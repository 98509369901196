.Splitter {
  height: 100%;
}

.Splitter.horizontal {
  display: flex;
  flex-direction: row;
}

/* gutter */
.gutter {
  background-color: #d3d5da;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter:hover {
  background-color: #bea9fd;
}

.gutter:active {
  background-color: #8e69fc;
}

/* gutter - horizontal */
.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAABCCAYAAABqzJeBAAAAAXNSR0IArs4c6QAAAEdJREFUSA1jqGrq+48PM+CTBMkxUA4IWkFQwagbwJFIeTAQju7RuIAE9Wg4jIYDUtlJOONQnjkJJjmCCkbdMFpOIifaQZAeAC+Vsa9rGBvEAAAAAElFTkSuQmCC");
  cursor: col-resize;
}

.gutter.gutter-horizontal:hover,
.gutter.gutter-horizontal:active {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAABCCAYAAABqzJeBAAAAAXNSR0IArs4c6QAAAEhJREFUSA1jCDTY9B8fZsAnCZJjoBwQtIKgglE3gCOR8mAgHN2jcQEJ6tFwGA0HpLKTcMahPHMSTHIEFYy6YbScRE60gyA9AABgB1U/3booqQAAAABJRU5ErkJggg==");
}

/* gutter - vertical */
.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAAICAYAAABNlyniAAAAAXNSR0IArs4c6QAAADZJREFUSA1jqGrq+w/CDFAwYvkj1uPoET8aENAcMRoQowEBzhzwhABnoOeZkcYfDYjRrIGSNQANebGvIUsdTAAAAABJRU5ErkJggg==");
  cursor: row-resize;
}

.gutter.gutter-vertical:hover,
.gutter.gutter-vertical:active {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAAICAYAAABNlyniAAAAAXNSR0IArs4c6QAAADdJREFUSA1jCDTY9B+EGaBgxPJHrMfRI340IKA5YjQgRgMCnDngCQHOQM8zI40/GhCjWQMlawAAtzFVP3J6kj0AAAAASUVORK5CYII=");
}
