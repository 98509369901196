.LNBPanel {
    position: fixed;
    width: 240px;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    z-index: 5;

    border-right: 1px solid var(--color-border-02, #d3d5da);
    background: var(--netural-l-100, #eceef0);
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.2);

    .logo_wrapper {
        border-bottom: 1px solid var(--color-border-02, #d3d5da);
        cursor: pointer;

        .logo {
            margin: 27px 0px 27px 20px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;

        .LNBMenu {
            width: 100%;
            display: grid;
            grid-template-rows: min-content 0fr;
            transition: grid-template-rows 400ms;

            word-break: break-all;
            white-space: pre-line;

            &.on {
                grid-template-rows: min-content 1fr;
            }

            button {
                width: inherit;
                display: flex;
                justify-content: space-between;
                padding: 8px 16px;
                transition: background 0.2s ease-in-out;
                color: #1c293e;

                > span {
                    text-align: left;
                }

                &.depth1 {
                    /* normal/body/02 */
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.13px;
                }

                &.depth2,
                &.depth3 {
                    /* normal/label/03 */
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: -0.024px;
                }

                &:hover {
                    background: var(--netural-l-150, #dedfe2);
                }
                &:active {
                    background: var(--netural-l-150, #dedfe2);
                }
                &.clicked {
                    background: #e1d7ff;
                    color: #6137df;
                    font-weight: 700;

                    &:hover {
                        background: #e1d7ff;
                    }

                    &:active {
                        background: #e1d7ff;
                    }
                }
            }

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                color: #434d5f;
                transition: all 0.18s ease-in-out;
                &.on {
                    transform: rotate(90deg);
                }
            }

            .subMenu {
                overflow: hidden;
            }
        }
    }
}
