.DeploymentProgress {
    margin-top: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image_container {
        position: relative;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #7a828e;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.004em;
    }
}
