.ag-theme-dui-excel {
  /* Borders */
  --ag-border-color: #d3d5da;

  /* Headers */
  --ag-header-height: 24px;
  --ag-header-foreground-color: #7a828e;
  --ag-header-background-color: #eceef0;
  --ag-header-cell-moving-background-color: #eceef0;

  /* Rows */
  --ag-row-height: 24px;
  --ag-grid-size: 4px;
  --ag-cell-horizontal-padding: 8px;
  --ag-font-size: 12px;
  --ag-data-color: #1c293e;
  --ag-row-border-color: #dedfe2;
  --ag-cell-horizontal-border: 1px solid #dedfe2;

  /* Cells */
  --ag-range-selection-border-color: transparent;

  /* Effect */
  --ag-row-hover-color: #f5f6f7;
  --ag-selected-row-background-color: #e8f5fe;
  --ag-icon-font-family: agGridBalham;

  /* Checkbox */
  --ag-checkbox-checked-color: #1898f5;
  --ag-checkbox-unchecked-color: #9ea4ac;
  --ag-checkbox-indeterminate-color: #1898f5;
}

.ag-theme-dui-excel .ag-header {
  /* compact/label/02 */
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.024px;
  border-bottom: 1px solid #d3d5da;
}

.ag-theme-dui-excel .ag-header-cell {
  border-right: 1px solid #d3d5da;
}

.ag-theme-dui-excel .ag-header-cell.header-centered .ag-header-cell-label {
  justify-content: center;
}
