.account {
    height: 100vh;
    padding: 8px;
    background-color: #f5f6f7;

    .passwordComfirmModalContent {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 12px;
    }
}
