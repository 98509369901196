/* your css code */
.SignUpWelcomePage {
    .page_content {
        width: 448px;

        .title {
            color: #1c293e;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.004em;
            margin-top: 28px;
            margin-bottom: 16px;
        }

        .explain_text {
            color: #7a828e;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.002em;
            text-align: center;
        }

        .bottom_text {
            color: #1c293e;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.002em;
        }
    }
}
