.Deployment {
    height: 100%;

    &.loading {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f6f7;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background: #f5f6f7;

        .chip_container {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
        }

        .button_container {
            display: flex;
            gap: 6px;
        }
    }

    .no_deployment {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background: #f5f6f7;

        .description {
            color: #7a828e;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.004em;
        }
    }

    .deployment_list {
        width: 100%;
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background: rgba(245, 246, 247, 1);
        border-radius: 4px;

        .cards {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
            overflow-y: scroll;
            gap: 16px;
            padding-bottom: 4px;
        }
    }

    .deployment_detail {
        overflow-y: scroll;
    }
}
