.card_active {
    background-color: #f1ecff;
}
.card_disabled {
    color: #7a828e;
    background-color: #f5f6f7;
}

.free-card_layout {
    width: 396px;
    height: 405px;
    border-radius: 4px;
    padding: 0 24px;

    .title_container {
        display: flex;
        flex-direction: column;
        width: 348px;
        height: 136px;
        text-align: center;
        align-items: center;
        padding: 24px 0;
        gap: 8px;

        .type {
            width: 59px;
            height: 24px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 500;
            padding: 2px 8px;
            border-radius: 12px;
            color: #6137df;
            background-color: #f1ecff;
            background: linear-gradient(
                    180deg,
                    #e1d7ff 0%,
                    rgba(225, 215, 255, 0) 100%
                ),
                linear-gradient(0deg, #f1ecff, #f1ecff);
        }
        .type_disabled {
            color: #7a828e;
            background: linear-gradient(
                    180deg,
                    #dedfe2 0%,
                    rgba(222, 223, 226, 0) 100%
                ),
                linear-gradient(0deg, #f5f6f7, #f5f6f7);
        }

        .title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            color: #1c293e;
        }

        .title_description {
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            color: #1c293e;
        }

        .disabled {
            color: #7a828e;
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        width: 348px;
        height: 108px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #7a828e;
    }

    .tier_container {
        display: flex;
        width: 348px;
        height: 112px;
        padding: 12px;
        gap: 10px 12px;

        .icon {
            margin-top: 10px;
        }

        .text_container {
            display: flex;
            flex-direction: column;
            width: 220px;
            height: 88px;
            margin-top: -4px;
            gap: 4px;
            .type {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #1c293e;
            }
            .spec {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: #1c293e;
            }
            .limit {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: #0485e3;
            }
        }
    }

    .tier_border {
        border: 2px solid #7244fb;
        border-radius: 4px;
        background-color: #ffffff;
    }

    .info_container {
        display: flex;
        width: 348px;
        height: 48px;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        justify-content: center;
        align-items: center;
        color: #6137df;
        padding: 14px 0;
        gap: 8px;
    }

    .disabled {
        color: #7a828e;
    }
}

.divider {
    width: 348px;
    border-top: 1px solid #dedfe2;
}
