.Overview {
    width: 100%;
    height: 100vh;

    min-width: 1100px;
    min-height: 600px;

    background-color: #f5f6f7;

    .loading_screen {
        width: 100%;
        height: 100vh;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}
