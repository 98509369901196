.UserInfo {
  width: 296px;
  border-radius: 4px;
  border-top: 5px solid var(--hot-pink-400, #ec81c7);
  background: var(--netural-l-0, #fff);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  display: none;
  position: absolute;
  top: 32px;
  right: 0;
}

.UserInfo.show {
  display: block;
}

.UserInfo .userInfo_wrapper {
  border-bottom: 1px #dedfe2 solid;
  padding: 28px 24px 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.UserInfo .userInfo_wrapper .name_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UserInfo .userInfo_wrapper .name_content .name {
  display: inline-flex;
  align-items: center;
  gap: 12px;
}

.UserInfo .userInfo_wrapper .name_content .name span {
  color: var(--color-text-primary, var(--netural-l-800, #1c293e));
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.064px;
  word-break: break-all;
}

.UserInfo .userInfo_wrapper .name_content .admin {
  padding: 3px 8px;
  border-radius: 12px;
  background: var(--netural-l-700, #434d5f);
  color: var(--color-text-inverse, var(--netural-l-0, #fff));
  font-size: 12px;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  letter-spacing: -0.024px;
  flex-shrink: 0;
}

.UserInfo .email {
  color: var(--color-text-secondary, var(--netural-l-500, #7a828e));
  font-size: 13px;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.026px;
  word-break: break-all;
}

.UserInfo .dropdown_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
}

.UserInfo .dropdown_wrapper .dropdown {
  display: flex;
  padding: 4px 10px;
  gap: 10px;
  color: var(--color-text-primary, var(--netural-l-800, #1c293e));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.026px;
  white-space: nowrap;
  cursor: pointer;
}

.UserInfo .dropdown_wrapper .dropdown:hover {
  background-color: #f5f6f7;
}

.UserInfo .dropdown_wrapper .dropdown:active {
  background-color: #dedfe2;
}