/* your css code */
.CopyrightLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 40px;
    color: #6e00ff;
    fill: currentColor;
}

.CopyrightLogo.blue {
    color: #002368;
}

.CopyrightLogo .tmaxsoft_logo-text {
    display: flex;
    gap: 10px;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.002em;
    text-align: center;
    color: #00000066;
}

.CopyrightLogo .tmaxsoft-announce_field {
    display: flex;
    gap: 8px;
}

.CopyrightLogo .text_button {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.002em;
    text-align: center;
    color: #00000066;
    text-decoration: underline;
    text-underline-position: under;
}

.CopyrightLogo .text_button:hover {
    opacity: 0.7;
}
