%shadow_box {
    border-bottom: 1px solid #dedfe2;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.OverviewDeploymentEmpty {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // @extend %shadow_box;

    .deployment_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .bottom_division {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .text_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .text_black {
                    color: #1c293e;
                    /* normal/title/04 */
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: -0.064px;
                    margin-bottom: 8px;
                }

                .text_grey {
                    color: #7a828e;
                    text-align: center;
                    /* normal/body/03 */
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: -0.06px;
                }
            }

            .button_container {
                margin-top: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;
            }
        }
    }
}
