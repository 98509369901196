.button {
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &.primary {
        background: #7244fb;
        color: #fff;
        &:hover {
            background: var(--purple-600, #6137df);
        }
        &:active {
            background: var(--purple-700, #5130b2);
        }
        &:disabled {
            cursor: default;
            background: var(--color-button-disable, #f5f6f7);
            color: var(--color-text-disabled, var(--netural-l-300, #b9bdc3));
        }
    }

    &.secondary {
        background: #fff;
        color: #1c293e;
        &:hover {
            background: #eceef0;
        }
        &:active {
            background: #dedfe2;
        }
        &:disabled {
            cursor: default;
            background: #fff;
            color: var(--color-text-disabled, var(--netural-l-300, #b9bdc3));
        }
    }

    &.sm {
        padding: 4px 16px;
        //styleName: compact/body/01;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.002em;
    }

    &.md {
        padding: 4px 16px;
        //styleName: normal/body/01;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.002em;
    }

    &.lg {
        padding: 6px 20px;
        //styleName: large/body/01;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.002em;
    }
}
