.commit_bar {
  position: fixed;
  bottom: 0;
  left: 240px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  width: calc(100vw - 240px);
  height: 44px;
  border-top: 1px solid var(--color-border-02, #d3d5da);
  background: var(--netural-l-0, #fff);
}
