.Event {
    width: 100%;
    min-width: 894px;
    height: 100vh;
    min-height: 600px;

    background-color: #f5f6f7;
    font-style: normal;
    color: var(--color-text-primary, var(--netural-l-800, #1c293e));
    padding: 8px;

    &.loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 890px;
        height: fit-content;
        border-radius: 4px;
        background: var(--netural-l-0, #fff);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
        padding: 12px 16px;

        .title_wrapper {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 14px;

            .title {
                font-size: 15px;
                font-weight: 500;
                line-height: 24px; /* 160% */
                letter-spacing: -0.06px;
            }

            .button {
                margin-top: 2px;
            }
        }
    }
}
