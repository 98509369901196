.button {
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.sm {
        width: 24px;
        height: 24px;
        padding: 4px;
        .icon {
            > :first-child {
                width: 16px;
                height: 16px;
            }
        }
    }

    &.md {
        width: 28px;
        height: 28px;
        padding: 5px;
        .icon {
            > :first-child {
                width: 18px;
                height: 18px;
            }
        }
    }

    &.lg {
        width: 36px;
        height: 36px;
        padding: 8px;
        .icon {
            > :first-child {
                width: 20px;
                height: 20px;
            }
        }
    }

    &.primary {
        &.filled {
            background: var(--purple-500, #7244fb);
            color: var(--color-text-inverse, var(--netural-l-0, #fff));

            &:hover {
                background: #6137df;
            }
            &:active {
                background: #5130b2;
            }
            &:disabled {
                background-color: #dedfe2;
                color: #b9bdc3;
            }
        }

        &.outline {
            border: 1px solid var(--color-border-brand-primary, #7244fb);
            background: var(--color-button-secondary-01, #fff);
            color: #6137df;

            &:hover {
                background: #eceef0;
            }
            &:active {
                background: #dedfe2;
            }
            &:disabled {
                border: 1px solid var(--color-border-disable, #eceef0);
                background: var(--color-button-secondary-01, #fff);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
                .icon {
                    color: inherit;
                }
            }
            .icon {
                color: #7244fb;
            }
        }

        &.ghost {
            color: #434d5f;

            &:hover:not(:disabled) {
                background: #eceef0;
            }
            &:active:not(:disabled) {
                background: #dedfe2;
            }
            &:disabled {
                color: #d3d5da;
            }
        }
    }

    &.secondary {
        color: #434d5f;

        &.filled {
            background: var(--color-button-secondary-02, #eceef0);

            &:hover {
                background: var(--color-button-secondary-hover-02, #dedfe2);
            }
            &:active {
                background: var(--color-button-secondary-active-02, #d3d5da);
            }
            &:disabled {
                background: var(--color-button-disable, #f5f6f7);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
            }
        }

        &.outline {
            border: 1px solid var(--color-border-02, #d3d5da);
            background: var(--color-button-secondary-01, #fff);

            &:hover {
                background: var(--color-button-secondary-hover-01, #eceef0);
            }
            &:active {
                background: #dedfe2;
            }
            &:disabled {
                border: 1px solid var(--color-border-disable, #eceef0);
                background: var(--color-button-secondary-01, #fff);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
            }
        }

        &.outlineCircle {
            border: 1px solid var(--color-border-02, #d3d5da);
            background: var(--color-button-secondary-01, #fff);
            border-radius: 50%;

            &:hover {
                background: var(--color-button-secondary-hover-01, #eceef0);
            }
            &:active {
                background: #dedfe2;
            }
            &:disabled {
                border: 1px solid var(--color-border-disable, #eceef0);
                background: var(--color-button-secondary-01, #fff);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
            }
        }

        &.ghost {
            &:hover:not(:disabled) {
                background: #eceef0;
            }
            &:active:not(:disabled) {
                background: #dedfe2;
            }
            &:disabled {
                color: #d3d5da;
            }
        }

        &.ghostNoBg {
            padding: 0;

            &:hover:not(:disabled) {
                color: #8e69fc;
            }
            &:active:not(:disabled) {
                color: #6137df;
            }
            &:disabled {
                color: #d3d5da;
            }
        }
    }

    &.delete {
        &.filled {
            background: var(--red-500, #ef4433);
            color: #ffffff;
            &:hover {
                background-color: #d93e2e;
            }
            &:active {
                background-color: #b82719;
            }
            &:disabled {
                background: var(--color-button-disable, #f5f6f7);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
            }
        }
        &.outline {
            border: 1px solid var(--color-border-error, #ef4433);
            background: var(--color-button-secondary-01, #fff);
            color: #ef4433;
            &:hover {
                background-color: #fdeceb;
            }
            &:active {
                background-color: #ffd1cd;
            }
            &:disabled {
                border: 1px solid var(--color-border-disable, #eceef0);
                background: var(--color-button-secondary-01, #fff);
                color: var(
                    --color-text-disabled,
                    var(--netural-l-300, #b9bdc3)
                );
                .icon {
                    color: inherit;
                }
            }
        }
        .icon {
            color: #ef4433;
        }
    }

    &:disabled {
        cursor: default;
    }

    &.dark {
        &.secondary {
            color: #ffffff;
            &.ghost {
                &:hover:not(:disabled) {
                    background: #353841;
                }
                &:active:not(:disabled) {
                    background: #2a2d37;
                }
                &:disabled {
                    color: #50535b;
                }
            }
        }
    }
}
