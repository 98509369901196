%box_frame {
    border-radius: 4px;
    /* shadow/card-01 */
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

%card_frame {
    height: 364px;
    padding: 12px 16px 16px 16px;
    @extend %box_frame;
}

%title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        color: #1c293e;
        /* normal/title/03 */
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.06px;
    }
}

.OverviewDeployment {
    width: 100%;
    height: 100%;
    padding: 44px 40px 40px 40px;
    display: grid;
    grid-template-columns: repeat(2, minmax(608px, 1fr));
    grid-template-rows: 364px 364px;
    gap: 16px;
    position: relative;

    .refresh_box {
        position: absolute;
        right: 16px;
        top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .refresh_text {
            color: #1c293e;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.002em;
        }
        // width: 100px;
        // height: 100px;
    }

    .state_card {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .top_container {
            width: 100%;
            height: 186px;
            padding: 32px;
            background: #7244fb;
            display: flex;
            gap: 37px;
            position: relative;
            @extend %box_frame;

            .svg_container {
                width: 120px;
                height: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .number_container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 8px;

                .total {
                    color: #ffffff;
                    /* normal/title/03 */
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: -0.06px;
                }

                .number {
                    color: #ffffff;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 350;
                    line-height: 60px;
                    letter-spacing: -0.096px;
                }
            }

            .button_container {
                display: flex;
                gap: 16px;
                position: absolute;
                right: 32px;
                bottom: 32px;
            }
        }

        .bottom_container {
            width: 100%;
            height: 162px;
            padding: 12px 16px 16px 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #ffffff;
            @extend %box_frame;

            .title_container {
                @extend %title_container;
            }

            .body_container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                width: 100%;
                gap: 2px;

                .status_container {
                    height: 88px;
                    padding: 12px 20px 12px 8px;
                    border-radius: 2px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title_box {
                        display: flex;
                        gap: 4px;
                        align-items: center;
                        .title {
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: -0.13px;
                        }
                        .icon {
                            display: flex;
                            > :first-child {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }

                    .count {
                        width: 100%;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 36px;
                        letter-spacing: -0.048px;
                        text-align: right;
                    }

                    &.REQUESTED {
                        color: #6137df;
                        background-color: #f1ecff;
                    }

                    &.ACTIVE {
                        color: #0485e3;
                        background-color: #e8f5fe;
                        .icon {
                            color: #1898f5;
                        }
                    }

                    &.ERROR {
                        color: #eaa317;
                        background-color: #fff8eb;
                        .icon {
                            color: #feb72c;
                        }
                    }

                    &.DELETED {
                        color: #7a828e;
                        background-color: #f5f6f7;
                        .icon {
                            color: #9ea4ac;
                        }
                    }

                    &.CREATE_IN_PROGRESS {
                        color: #345ec9;
                        background-color: #ecf1fd;
                        .icon {
                            color: #3d6eec;
                        }
                        .title_box {
                            align-items: flex-start;
                            word-break: keep-all;
                        }
                        .count {
                            position: relative;
                            top: -12px;
                        }
                    }
                }
            }
        }
    }

    // invoice card 구역
    .invoice_card {
        background-color: #ffffff;
        position: relative;
        @extend %card_frame;

        .title_container {
            @extend %title_container;
        }

        .body_container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10%;
            padding: 0 24px 22px 24px;

            .amount_box {
                height: 84px;
                width: 55%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background: #f5f6f7;

                .amount {
                    color: #6137df;
                    /* normal/chart/01 */
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 350;
                    line-height: 60px;
                    letter-spacing: -0.08px;
                }
            }
        }
        .invoice_date {
            position: absolute;
            right: 24px;
            bottom: 22px;

            /* normal/helper/01 */
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.024px;

            .label {
                color: #9ea4ac;
            }
            .date {
                color: #7a828e;
            }
        }
    }

    .event_card {
        background-color: #ffffff;
        @extend %card_frame;

        .title_container {
            margin-bottom: 16px;
            @extend %title_container;
        }

        .body_container {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 16px;
            width: 100%;

            .level_container {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 2px;

                .level_box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 8px 0;
                    height: 78px;
                    background-color: pink;
                    border-radius: 2px;

                    .level {
                        /* normal/body/01 */
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: -0.026px;
                    }

                    .count {
                        /* normal/chart/02 */
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 36px;
                        letter-spacing: -0.048px;
                    }
                }
            }

            .list_container {
                width: 100%;
                .column_box {
                    height: 40px;
                    width: 100%;
                    display: grid;
                    grid-template-columns: 0.3fr 1fr 1fr 0.7fr;
                    border-bottom: 2px solid #d3d5da;
                    gap: 2px;

                    .text_box {
                        padding: 10px 8px;
                        display: flex;
                        align-items: center;

                        .name,
                        .description,
                        .time {
                            color: #9a9ba0;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px; /* 153.846% */
                            letter-spacing: -0.026px;
                        }
                    }
                }
                .list_box {
                    overflow-y: auto;
                    height: 170px;
                    width: 100%;

                    .list {
                        width: 100%;
                        height: 48px;
                        display: grid;
                        grid-template-columns: 0.3fr 1fr 1fr 0.7fr;
                        align-items: center;
                        gap: 2px;

                        img {
                            padding-left: 11px;
                        }

                        .text_box {
                            padding: 10px 8px;
                            display: flex;
                            align-items: center;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;

                            .name,
                            .description {
                                color: #1c293e;
                                /* normal/body/01 */
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px;
                                letter-spacing: -0.026px;
                            }

                            .time {
                                color: #9ea4ac;
                                /* normal/body/01 */
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px;
                                letter-spacing: -0.026px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }
    }

    .notification_card {
        background-color: #ffffff;
        width: 100%;
        @extend %card_frame;

        .title_container {
            margin-bottom: 8px;
            @extend %title_container;
        }

        .body_container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .noti_box {
                height: 84px;
                padding: 18px 16px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .title {
                        width: 350px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;

                        color: #1c293e;
                        /* normal/body/02 */
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: -0.13px;
                    }
                    .date {
                        color: #9ea4ac;
                        /* normal/helper/01 */
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: -0.024px;
                    }
                }

                .text_box {
                    .text {
                        width: 430px;
                        height: 20px;
                        display: block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;

                        color: #7a828e;
                        /* normal/body/01 */
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: -0.026px;
                    }
                }
            }
        }
    }
}
