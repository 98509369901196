.profileWrapper {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.profileBadge {
  width: 18px;
  height: 18px;
  viewbox: 0 0 18 18;
}

.profileBadge.medium {
  width: 24px;
  height: 24px;
  viewbox: 0 0 24 24;
}

.profileBadge.large {
  width: 32px;
  height: 32px;
  viewbox: 0 0 32 32;
}

.profileBadge circle {
  cx: 9px;
  cy: 9px;
  r: 8.5px;
}

.profileBadge.medium circle {
  cx: 11px;
  cy: 11px;
  r: 10.5px;
}

.profileBadge.large circle {
  cx: 15px;
  cy: 15px;
  r: 14.5px;
}

.profileBadge text {
  text-anchor: middle;
  alignment-baseline: middle;
  transform: translate(8.5px, 10.5px);
}

.profileBadge.medium text {
  transform: translate(10.5px, 12.5px);
}

.profileBadge.large text {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.036px;
  transform: translate(14.5px, 16.5px);
}

.profileBadge.colorVariation_1 {
  fill: #843a6b;
}

.profileBadge.colorVariation_1 circle {
  stroke: #f5bde2;
  fill: #f9daef;
}
