.selectContainer {
    position: relative;
    width: fit-content;

    .trigger {
        padding: 4px 8px;
        width: 245px;
        height: 28px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #d3d5da;
    }

    .text {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.002em;
        align-items: middle;
        display: block;
        text-align: start;
    }

    .icon {
        width: 16px;
        height: 16px;
    }
}
