.link {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.002em;
    cursor: pointer;

    &.hidden {
        color: #1c293e;
        &:hover {
            color: #46adf7;
            text-decoration: underline;
        }
        &:active {
            color: #0485e3;
        }
    }

    &.hiddenArrow {
        color: #1c293e;
        .icon {
            color: #434d5f;
            width: 18px;
            height: 18px;
        }
        &:hover {
            color: #46adf7;
            text-decoration: underline;
            .icon {
                color: inherit;
            }
        }
        &:active {
            color: #0485e3;
            .icon {
                color: inherit;
            }
        }
    }

    &.exposed {
        color: #1898f5;
        text-decoration: underline;
        &:hover {
            color: #46adf7;
        }
        &:active {
            color: #0485e3;
        }
    }
}
