.SimpleModal .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  background: #000;
  z-index: 100;
}

.SimpleModal .dialog_container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-height: 80vh;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.3);
  width: 400px;
  opacity: 1;
  z-index: 101;
}

.SimpleModal .dialog_container.md {
  width: 560px;
}

.SimpleModal .dialog_container.lg {
  width: 720px;
}

.SimpleModal .dialog_container.xl {
  width: 960px;
}

.SimpleModal .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;

  color: var(--color-text-primary, var(--netural-l-800, #1c293e));
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.064px;
}

.SimpleModal .header button {
  background: none;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.SimpleModal .header button .icon {
  width: 24px;
  height: 24px;
  color: #9ea4ac;
  transition: all 0.2s ease-in-out;
}

.SimpleModal .header button .icon:hover {
  color: #86888d;
}

.SimpleModal .body {
  margin-top: 16px;
  padding: 0 10px;
  max-height: calc(80vh - 124px);
  overflow-y: auto;

  color: var(--color-text-primary, var(--netural-l-800, #1c293e));
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.026px;
}

.SimpleModal .footer {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 16px;
  height: 28px;
  gap: 8px;
}
