/* AG-Grid Custom Theme */
@import "../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/styles/ag-theme-balham.css";
@import "./styles/ag-theme-dui.css";
@import "./styles/ag-theme-dui-excel.css";

/* font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 250;
  src: url(./assets/fonts/NotoSansCJKkr-Thin.otf) format("opentype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/NotoSansCJKkr-Light.otf) format("opentype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 350;
  src: url(./assets/fonts/NotoSansCJKkr-DemiLight.otf) format("opentype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/NotoSansCJKkr-Regular.otf) format("opentype");
}
@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/NotoSansCJKkr-Medium.otf) format("opentype");
}
@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/NotoSansCJKkr-Bold.otf) format("opentype");
}
@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 900;
  src: url(./assets/fonts/NotoSansCJKkr-Black.otf) format("opentype");
}

.App {
  height: inherit;
  width: inherit;
}

* {
  /* font-family: "NotoSansCJKkr" !important; */
  font-family: "Noto Sans KR", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
}

body {
  height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
  margin: 0;
}

/* scroll bar style */
html body ::-webkit-scrollbar {
  background: transparent !important;
}

html body #pageBody ::-webkit-scrollbar {
  background: transparent !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar:vertical {
  width: 12px !important;
}

::-webkit-scrollbar:horizontal {
  height: 12px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(190, 189, 190, 1) !important;
  border: 2px solid white !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(157, 156, 157, 1) !important;
  border-radius: 10px !important;
}

/* Page selection */
:not(.logo_text, .PageTitle)::selection {
  background-color: #f1ecff;
}
