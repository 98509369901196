.NotificationToast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    min-width: 280px;
    max-width: 528px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 4;

    padding: 14px 13px 14px 24px;
    background-color: #2a2d37;
    border: 1px solid #50535b;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);

    animation: slideFromBottom 0.5s forwards;

    &.slideout {
        animation: slideToBottom 5s forwards;
    }

    .notificationToastContent {
        color: #ffffff;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.002em;
        white-space: pre-wrap;
    }
}

@keyframes slideFromBottom {
    from {
        bottom: -8px;
    }
    to {
        bottom: 8px;
    }
}

@keyframes slideToBottom {
    from {
        bottom: 8px;
    }
    to {
        bottom: -100%;
    }
}
