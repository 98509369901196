@mixin shake {
    animation: shake 0.5s ease-in-out;
}

@keyframes shake {
    0% {
        transform: translateX(1px);
    }
    100% {
        transform: translateX(0);
    }
    15%,
    85% {
        transform: translateX(-2px);
    }
    25%,
    75% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
}
