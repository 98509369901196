.breadcrumbContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.itemContainer {
    position: relative;
    display: inline-block;

    height: 16px;
    padding: 2px;

    .breadcrumbTypo {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
        letter-spacing: -0.024px;
        // vertical-align: middle;
        display: flex;
        align-self: center;
        color: #1c293e;
        text-transform: capitalize;
    }
    &:hover {
        border-radius: 4px;
        background: var(--color-layer-hover-01, #f5f6f7);
    }
    &:active {
        border-radius: 4px;
        background: var(--color-layer-active-01, #dedfe2);
    }

    &.current {
        .breadcrumbTypo {
            color: #0d5487;
            color: #0485e3;
        }
    }

    + .itemContainer {
        margin-left: 24px;

        &::before {
            content: '';
            mask-image: url('../../../assets/icons/keyboard_arrow_right.svg');
            background-color: #9ea4ac;
            mask-size: 18px;
            width: 18px;
            height: 18px;
            position: absolute;
            left: -20px;
        }
    }
}
