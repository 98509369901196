.SignUpAccountInfoPage {
    height: inherit;
    min-width: 800px;

    .header {
        display: flex;
        min-width: 800px;
        justify-content: space-between;
        width: 100vw;
        height: 64px;
        z-index: 4;
        position: fixed;
        top: 0px;
        left: 0px;
        display: flex;
        padding: 22px 40px;
        align-items: center;
        border-bottom: 1px solid var(--color-border-01, #dedfe2);
        background: var(--netural-l-0, #fff);
        box-shadow: 0px 1px 4px 0px #00000033;

        .button_wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            h4 {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.002em;
                text-align: left;
                color: #7a828e;
            }

            button {
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 15px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.004em;
                text-align: left;
                color: #1c293e;

                .icon {
                    color: #1c293e;
                    transition: all 0.2s ease-in-out;
                }

                &:hover > .icon {
                    transform: translateX(4px);
                }
            }
        }
    }
    .content_wrapper {
        display: flex;
        min-width: 800px;
        align-items: center;
        flex-direction: column;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.026px;
        height: inherit;
        margin-top: 64px;
        padding-bottom: 40px;

        .title_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            margin-top: 48px;
            margin-bottom: 32px;

            h1 {
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: -0.002em;
            }
        }
    }
    .card_wrapper {
        width: 544px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.026px;
        height: inherit;

        .card_top {
            width: 544px;
            height: 4px;
            border-radius: 8px 8px 0px 0px;
            background: #7244fb;
        }

        .card_content {
            width: 544px;
            padding: 32px;
            border-radius: 0px 0px 8px 8px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            background: #ffffff;
            box-shadow: 0px 1px 4px 0px #00000033;

            h2 {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.01em;
                text-align: left;
            }

            .warning_text {
                color: #f2695c;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: -0.024px;
            }
        }
    }

    .input_wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.002em;
            text-align: left;
            color: #7a828e;
        }

        &.short {
            width: 236px;
        }
    }

    .field_container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .field_wrapper {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        &.horizontal {
            flex-direction: column;
        }
    }

    .input_field {
        width: 146px;
        height: 40px;
        padding: 10px 12px;
        border: 1px solid #d3d5da;
        outline: none;
        background: '#FFFFFF';
        border-radius: 4px;
        box-sizing: border-box;

        &::placeholder {
            color: #9ea4ac;
            font-weight: 400;
        }

        &:valid {
            color: #1c293e;
            font-weight: 400;
        }
    }

    .text_container {
        display: flex;
        flex-direction: column;

        span {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.002em;
            text-align: left;
            color: #7a828e;
            transition: all 0.2s ease-in-out;
        }

        span.green {
            color: #e0e0e0;
            color: #2cad7e;
        }
    }

    .checkbox_container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .check_text {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.002em;
            text-align: left;
            word-wrap: break-word;
            width: 365px;
        }
    }

    .checkbox {
        width: 18px;
        height: 18px;
    }
}
