@use 'src/styles/mixins/_shake' as *;

.SignUpConfirmCodeForm {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .field_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        .confirm_code_wrapper {
            display: flex;
            justify-content: center;
            gap: 8px;
        }

        .warning_text {
            color: #f2695c;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.002em;

            &.shake {
                @include shake;
            }
        }
    }

    .mail_yet {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        span {
            color: #7a828e;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.002em;
        }
    }
}
