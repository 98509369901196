@use 'src/styles/mixins/_shake' as *;

.ResetPassword {
    .field_container {
        display: flex;
        flex-direction: column;

        gap: 4px;

        .text {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            justify-content: center;
            color: var(--netural-l-500, #7a828e);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.026px;
        }

        .resend_code {
            cursor: pointer;
            color: #6137df;
            transition: all 0.2s ease-in-out;
        }

        .resend_code:hover {
            color: #8e69fc;
        }
    }

    .warning_container {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.002em;
        text-align: left;
        color: #7a828e;
    }

    .green {
        color: #2cad7e;
    }
}

.shake {
    @include shake;
}
