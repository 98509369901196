.billing {
    min-height: 100vh;
    max-height: max-content;
    padding: 8px;
    background-color: #f5f6f7;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .billingCard {
        width: 100%;
        border-radius: 4px;
        background: var(--netural-l-0, #fff);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
        width: 912px;

        .container {
            padding: 16px;
            color: #1c293e;

            &:first-child {
                padding-top: 12px;
            }
            &:not(:first-child) {
                border-top: 1px solid #dedfe2;
            }

            .header {
                h2 {
                    /* normal/title/03 */
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 24px; /* 160% */
                    letter-spacing: -0.06px;
                }

                h3 {
                    /* normal/label/02 */
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px; /* 153.846% */
                    letter-spacing: -0.026px;
                    display: flex;
                    gap: 8px;

                    span {
                        color: #6137df;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .total_amount {
                    color: #6137df;
                    margin-top: 8px;
                    /* normal/chart/02 */
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 36px; /* 150% */
                    letter-spacing: -0.048px;
                }

                .date_container {
                    display: flex;
                    gap: 8px;

                    .vertical_wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }
                }

                .chip_container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;
                    margin-top: 16px;
                }
            }
        }
    }
}
