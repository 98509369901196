.container {
    width: 100vw;
    z-index: 4;
    position: fixed;
    top: 0px;
    left: 240px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border-01, #dedfe2);
    background: var(--netural-l-0, #fff);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);

    &.previous {
        left: 64px;
    }

    .wrapper {
        width: 100%;
        height: 64px;
        padding: 16px 268px 16px 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &.previous {
            padding: 16px 92px 16px 16px;
        }

        .title_wrapper {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .title {
                color: var(--color-text-primary, var(--netural-l-800, #1c293e));
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 133.333% */
                letter-spacing: -0.036px;
                text-transform: capitalize;
            }
        }

        .button_container {
            position: relative;
            display: flex;
            align-items: center;
            gap: 8px;

            .icon {
                display: flex;
                align-items: center;
                cursor: pointer;
                border: none;
                background-color: #fff;
                padding: 4px;
                border-radius: 16px;

                &:hover {
                    background: var(--netural-l-50, #f5f6f7);
                }

                &:active {
                    background: var(--netural-l-150, #dedfe2);
                }
            }
        }
    }
}
