%loading_dot {
    width: 8px;
    height: 8px;
    background-color: #7244fb;
    opacity: 0.3;
    border-radius: 50%;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.Loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .text_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 10px;

        .text_top {
            color: #6137df;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            letter-spacing: -0.064px;
        }
        .text_bottom {
            color: #7a828e;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 160% */
            letter-spacing: -0.06px;
        }
    }

    .loading_bar_container {
        width: 130px;
        height: 10px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        // overflow: hidden;
        // border-radius: 20px;

        .loading_dot1 {
            @extend %loading_dot;
            animation-name: dot_ani1;
            animation-delay: 0s;
        }
        .loading_dot2 {
            @extend %loading_dot;
            animation-name: dot_ani2;
            animation-delay: 0.2s;
        }
        .loading_dot3 {
            @extend %loading_dot;
            animation-name: dot_ani3;
            animation-delay: 0.4s;
        }
        .loading_dot4 {
            @extend %loading_dot;
            animation-name: dot_ani4;
            animation-delay: 0.6s;
        }

        // .loading_bar {
        //     position: absolute;
        //     width: 130px;
        //     height: 8px;
        //     background-color: #e0e0e0;
        //     border-radius: 20px;
        // }

        // .loading_bar_moving {
        //     position: absolute;
        //     width: 14px;
        //     height: 8px;
        //     border-radius: 20px;
        //     background-color: #1c293e;

        //     animation-name: loading_ani;
        //     animation-duration: 2s;
        //     animation-timing-function: linear;
        //     animation-iteration-count: infinite;
        // }
    }
}

// @keyframes loading_ani {
//     0% {
//         transform: translateX(-90px);
//     }
//     100% {
//         transform: translateX(90px);
//     }
// }

@keyframes dot_ani1 {
    0% {
        transform: scale(1, 1);
        opacity: 0.3;
    }
    25% {
        transform: scale(1.5, 1.5);
        opacity: 1;
    }
    50% {
        transform: scale(1, 1);
        opacity: 0.3;
    }
}
@keyframes dot_ani2 {
    0% {
        transform: scale(1, 1);
        opacity: 0.3;
    }
    25% {
        transform: scale(1.5, 1.5);
        opacity: 1;
    }
    50% {
        transform: scale(1, 1);
        opacity: 0.3;
    }
}
@keyframes dot_ani3 {
    0% {
        transform: scale(1, 1);
        opacity: 0.3;
    }
    25% {
        transform: scale(1.5, 1.5);
        opacity: 1;
    }
    50% {
        transform: scale(1, 1);
        opacity: 0.3;
    }
}
@keyframes dot_ani4 {
    0% {
        transform: scale(1, 1);
        opacity: 0.3;
    }
    25% {
        transform: scale(1.5, 1.5);
        opacity: 1;
    }
    50% {
        transform: scale(1, 1);
        opacity: 0.3;
    }
}
