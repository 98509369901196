.card {
    width: 100%;
    border-radius: 4px;
    background: var(--netural-l-0, #fff);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);

    .info_container {
        padding: 16px;

        .header {
            display: flex;
            h2 {
                margin-bottom: 24px;
                margin-right: 8px;
                max-width: 100%;
                min-width: 240px;
                color: #1c293e;
                /* normal/title/03 */
                font-size: 15px;
                font-weight: 500;
                line-height: 24px; /* 160% */
                letter-spacing: -0.06px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .horizontal_wrapper {
                display: flex;
                align-items: flex-start;

                display: grid;
                grid-template-columns: 240px 1fr;
                gap: 8px;

                .label {
                    width: 240px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                }
                .help {
                    color: var(
                        --color-text-helper,
                        var(--netural-l-500, #7a828e)
                    );
                    /* normal/helper/01 */
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px; /* 133.333% */
                    letter-spacing: -0.024px;
                }
            }
        }

        &:last-child .content {
            margin-bottom: 32px;
        }
    }

    hr {
        background: #dedfe2;
        height: 1px;
        border: 0;
    }
}

.commit_button_container {
    width: 100%;
    display: flex;
    gap: 8px;
}

.leaveSiteModalContent {
    height: 80px;
    p {
        color: var(--color-text-primary, var(--netural-l-800, #1c293e));
        /* normal/body/01 */
        font-size: 13px;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        letter-spacing: -0.026px;
    }
}
